import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Cascader, Form, Input, Result, Spin, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { sendNotificationToTopic } from '../hooks/FirebaseFunctions';

const { TextArea } = Input;

const notificationTopicOption = [
  {
    value: 'notice',
    label: '一般',
  },
  {
    value: 'dev',
    label: '開發',
  },
];

const InputFormItem = ({ name, label, isRequired, isTextArea = false, textAreaRows = 4, tooltip }) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required: isRequired, message: '必需填寫' }]} tooltip={tooltip}>
      {isTextArea ? <TextArea row={textAreaRows} /> : <Input />}
    </Form.Item>
  );
};

const SwitchFormItem = ({ name, label, isRequired, tooltip }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      valuePropName="checked"
      rules={[{ required: isRequired, message: '必需填寫' }]}
      tooltip={tooltip}
    >
      <Switch />
    </Form.Item>
  );
};

export default function NotificationPage() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [submitResult, setSubmitResult] = useState();
  const { noticeId } = history.location.state ?? {};

  const formInitialValues = {
    topic: ['notice'],
    noticeId: noticeId,
    autoClose: true,
    jumpToNotice: false,
    showDialog: !!noticeId,
  };

  const onValidSubmit = values => {
    setIsLoading(true);
    values.topic = values.topic[0];

    console.log(values);
    sendNotificationToTopic({
      ...values,
      onSuccess: () => {
        setIsLoading(false);
        setSubmitResult(true);
      },
      onFail: () => {
        setIsLoading(false);
        setSubmitResult(false);
      },
    });
  };

  const resetPage = () => {
    form.resetFields();
    setSubmitResult();
  };

  if (submitResult) {
    return (
      <Result
        status="success"
        title="成功"
        subTitle={'成功發送推送通知'}
        extra={[
          <Button type="primary" key="notification" onClick={resetPage}>
            返回
          </Button>,
        ]}
      />
    );
  } else {
    return (
      <Spin spinning={isLoading} indicator={<LoadingOutlined spin style={{ fontSize: 48 }} />}>
        <Form
          form={form}
          name="notice"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          size="default"
          onFinish={onValidSubmit}
          initialValues={formInitialValues}
        >
          <Form.Item name="topic" label="頻道" rules={[{ required: true, message: '必需填寫' }]}>
            <Cascader options={notificationTopicOption} />
          </Form.Item>
          <InputFormItem name="title" label="標題" isRequired={true} />
          <InputFormItem
            name="body"
            label="內文"
            isTextArea={true}
            textAreaRows={4}
            isRequired={true}
            tooltip="請注意推道通知內文有限"
          />
          <Form.Item
            tooltip="如編號正確，用家打開推道通知時會打會訊息視窗"
            shouldUpdate={(prevValues, curValues) => prevValues.showDialog !== curValues.showDialog}
            noStyle
          >
            {form => {
              const showDialog = form.getFieldValue('showDialog');
              return (
                <>
                  <Form.Item
                    label="訊息"
                    name="showDialog"
                    valuePropName="checked"
                    tooltip="打開推道通知時會打會訊息視窗"
                  >
                    <Switch />
                  </Form.Item>
                  {showDialog && (
                    <InputFormItem name="noticeId" label="訊息編號" isRequired={true} tooltip="必須填寫正確編號" />
                  )}
                </>
              );
            }}
          </Form.Item>
          <SwitchFormItem name="autoClose" label="自動關閉" />
          <SwitchFormItem name="jumpToNotice" label="跳到通知分頁" />
          <Button danger className="mx-1" onClick={() => form.resetFields()}>
            重設
          </Button>
          <Button type="primary" className="mx-1" htmlType="submit">
            確定
          </Button>
        </Form>
      </Spin>
    );
  }
}
