import React, { useState } from 'react';
import { Avatar, Button, Input, List, Tooltip, Typography } from 'antd';
import { PlusCircleTwoTone, MinusCircleTwoTone, UserOutlined, QuestionOutlined } from '@ant-design/icons';

import { useAuthContext } from '../contexts/AuthContext';
import { useFirestoreContext } from '../contexts/FirestoreContext';
import { getAuthenticatedUserByEmail } from '../hooks/FirebaseFunctions';

const { Search } = Input;
const { Title } = Typography;

const SearchResultCard = ({ email, uid, displayName, photoURL, actions = [] }) => {
  if (!email || !uid) return null;
  return (
    <List.Item actions={actions}>
      <List.Item.Meta
        className="flex flex-row justify-center align-middle"
        avatar={<Avatar src={photoURL} />}
        title={displayName}
        description={email}
      />
    </List.Item>
  );
};

const EmptySearchResult = () => {
  return (
    <List className="border-l rounded-bl rounded-br border-r border-b pt-1 pl-4">
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar icon={<QuestionOutlined />} className="flex flex-col justify-center" />}
          title="找不到此帳號"
          description="請確定該帳號曾登入"
        />
      </List.Item>
    </List>
  );
};

export default function SettingPage() {
  const { currentUser } = useAuthContext();
  const { admins, addAdmin, removeAdmin } = useFirestoreContext();
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [isAdding, setIsAdding] = useState(false);

  const onSearch = searchKey => {
    setIsSearching(true);
    setSearchResult();
    console.log(searchKey);
    getAuthenticatedUserByEmail(searchKey).then(
      result => {
        console.log(result);
        setSearchResult(result.user);
        setIsSearching(false);
      },
      rejectReason => {
        setSearchResult();
        setIsSearching(false);
      }
    );
  };

  return (
    <div>
      <Title level={5}>搜尋已登入帳號以新增管理員</Title>
      <Search
        placeholder="帳號電子郵件"
        onSearch={onSearch}
        enterButton
        disabled={isSearching}
        loading={isSearching}
        prefix={<UserOutlined />}
      />
      {searchResult === null ? <EmptySearchResult /> : null}
      {searchResult && (
        <>
          <List className="border-l rounded-bl rounded-br border-r border-b pt-1 pl-4">
            <SearchResultCard
              email={searchResult.email}
              uid={searchResult.uid}
              photoURL={searchResult.photoURL}
              displayName={searchResult.displayName}
              actions={[
                <Button
                  type="link"
                  loading={isAdding}
                  icon={
                    admins.find(value => value.uid === searchResult.uid) ? null : (
                      <Tooltip title="新增帳號管理員">
                        <PlusCircleTwoTone style={{ fontSize: '24px' }} twoToneColor="#52c41a" />
                      </Tooltip>
                    )
                  }
                  onClick={async () => {
                    setIsAdding(true);
                    await addAdmin(searchResult).then(result => {
                      setIsAdding(false);
                      if (result) setSearchResult();
                      else console.log('fail');
                    });
                  }}
                />,
              ]}
            />
          </List>
        </>
      )}
      <List header="管理員" className="mt-8">
        {admins.map((value, index) => {
          return (
            <SearchResultCard
              key={value.uid}
              email={value.email}
              uid={value.uid}
              photoURL={value.photoURL}
              displayName={value.displayName}
              actions={[
                <Button
                  type="link"
                  disabled={value.uid === currentUser?.uid}
                  icon={
                    value.uid === currentUser?.uid ? null : (
                      <Tooltip title="移除帳號管理員">
                        <MinusCircleTwoTone style={{ fontSize: '24px' }} twoToneColor="#f5222d" />
                      </Tooltip>
                    )
                  }
                  onClick={async () => {
                    await removeAdmin(value.uid).then(result => {
                      console.log(result ? 'success' : 'fail');
                    });
                  }}
                />,
              ]}
            />
          );
        })}
      </List>
    </div>
  );
}
