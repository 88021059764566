import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Layout, Menu, Breadcrumb, Typography } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';

import { useAuthContext } from '../contexts/AuthContext';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

const PageMenuItem = ({ isGroup, path, title, icon, members, ...props }) => {
  if (isGroup) {
    return <GroupMenuItem title={title} icon={icon} members={members} {...props} />;
  } else {
    return <RouteMenuItem path={path} title={title} icon={icon} members={members} {...props} />;
  }
};

const GroupMenuItem = ({ title, icon, members, ...props }) => {
  return (
    <SubMenu key={'menu-group-' + title} icon={icon} title={title} {...props}>
      {members
        .filter(value => !value.isHidden)
        .map((member, _) => {
          return <PageMenuItem key={'menu-item-' + member.path} {...member} />;
        })}
    </SubMenu>
  );
};

const RouteMenuItem = ({ path, title, icon, component: PageComponent, ...props }) => {
  const history = useHistory();

  return (
    <Menu.Item icon={icon} {...props} onClick={() => history.push(path)}>
      {title}
    </Menu.Item>
  );
};

const ActionMenuItem = ({ title, icon, onClick, ...props }) => {
  return (
    <Menu.Item key={'menu-item-action-' + title} icon={icon} onClick={onClick} {...props}>
      {title}
    </Menu.Item>
  );
};

const getPathToTitles = (members, parentTitles = [], table = []) => {
  members.forEach((member, _) => {
    const titles = [...parentTitles, member.title];
    if (member.isGroup) {
      table = getPathToTitles(member.members, titles, table);
    } else {
      table = [...table, { path: member.path, titles: titles }];
    }
  });
  return table;
};

export default function MainLayout({ pageData, children }) {
  const location = useLocation();
  const { isSignedIn, role, signOut, currentUser } = useAuthContext();
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const onSiderCollapse = collapsed => setSiderCollapsed(collapsed);
  const [titlesTable, setTitlesTable] = useState([]);

  useEffect(() => {
    setTitlesTable(getPathToTitles(pageData));
  }, [pageData]);

  return (
    <Layout className="min-h-screen">
      <Sider collapsible collapsed={siderCollapsed} onCollapse={onSiderCollapse}>
        <div className="w8 m-4 bg-gray-300" />
        {isSignedIn && (
          <>
            <div className={'flex items-center ' + (siderCollapsed ? 'justify-center' : 'justify-start')}>
              <Avatar size="middle" src={currentUser?.photoURL} className="mx-4" />
              {!siderCollapsed && <Text className="text-gray-300">{currentUser?.displayName}</Text>}
            </div>
            <div className="w8 m-4 bg-gray-300" />
          </>
        )}
        <Menu theme="dark" selectedKeys={['menu-item-' + location.pathname]} mode="inline">
          {pageData
            .filter(value => !value.isHidden)
            // if signin and is admin, show all, else show public only
            .filter(value => {
              return value.roles === '*' || (isSignedIn && value.roles.some(value => value === role));
            })
            .map((value, _) => {
              // special handling for login
              if (value.path === '/login' && isSignedIn) {
                return null;
              } else {
                return <PageMenuItem key={'menu-item-' + value.path} {...value} />;
              }
            })}
          {/* logout button */}
          {isSignedIn && (
            <ActionMenuItem danger key="logout" title="登出" icon={<UnlockOutlined />} onClick={signOut} />
          )}
        </Menu>
      </Sider>
      <Layout className="bg-white dark:bg-gray-900">
        <Header className="bg-white dark:bg-gray-900 p-0" />
        <Content className="my-0 mx-4">
          <Breadcrumb className="mx-4 my-0">
            <Breadcrumb.Item>控制台</Breadcrumb.Item>
            {titlesTable
              .find((value, _) => value.path === location.pathname)
              ?.titles.map((value, index) => {
                return <Breadcrumb.Item key={'breadcrumb-' + index}>{value}</Breadcrumb.Item>;
              })}
          </Breadcrumb>
          <div className="bg-white dark:bg-gray-900 p-6">{children}</div>
        </Content>
        <Footer className="text-center p-4">ntkbc-cow-front control panel- ©2020 Created by NTKBC</Footer>
      </Layout>
    </Layout>
  );
}
