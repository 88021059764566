import React from 'react';
import { useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';
import { format } from 'date-fns';
import { Button, Table, Tooltip, Popconfirm } from 'antd';
import {
  EyeInvisibleOutlined,
  LinkOutlined,
  ExclamationCircleTwoTone,
  DeleteTwoTone,
  CloseCircleTwoTone,
  SendOutlined,
} from '@ant-design/icons';

import { useFirestoreContext } from '../contexts/FirestoreContext';

const formatDate = timestamp => format(timestamp, 'yyyy/MM/dd  aa hh:mm');

export default function MessageListPage() {
  const { notices, deleteNotice } = useFirestoreContext();
  const history = useHistory();

  const columns = [
    {
      title: '標題',
      dataIndex: 'title',
      key: 'title',
      render: title => {
        return <Truncate lines={1}>{title}</Truncate>;
      },
    },
    {
      title: '內容',
      dataIndex: 'content',
      key: 'content',
      render: content => {
        if (!content) return null;
        return (
          <>
            <Truncate class="inline-flex w-full" lines={1}>
              {content[0]}
            </Truncate>
            {content.length > 1 ? <span>...</span> : null}
          </>
        );
      },
    },
    {
      title: '參考',
      dataIndex: 'refUrl',
      key: 'refUrl',
      align: 'center',
      width: 64,
      render: refUrl => {
        return <> {refUrl && <LinkOutlined className="text-blue-500" />}</>;
      },
    },
    {
      title: '顯示',
      dataIndex: 'isHidden',
      key: 'isHidden',
      align: 'center',
      width: 64,
      render: isHidden => {
        return <> {!!isHidden && <EyeInvisibleOutlined className="text-gray-500" />}</>;
      },
    },
    {
      title: '重要',
      dataIndex: 'isImportant',
      key: 'isImportant',
      align: 'center',
      width: 64,
      render: isImportant => {
        return !!isImportant ? <ExclamationCircleTwoTone twoToneColor="#f5222d" /> : null;
      },
    },
    {
      title: '建立時間',
      dataIndex: 'createTimestamp',
      key: 'createTimestamp',
      width: 180,
      render: createTimestamp => {
        return <>{formatDate(createTimestamp?.toDate())}</>;
      },
    },
    {
      title: '',
      key: 'operation_sendNotification',
      align: 'center',
      width: 1,
      render: ({ id }) => {
        return (
          <Tooltip title="發送推送通知">
            <Button type="link" size="small" onClick={() => history.push('/notification', { noticeId: id })}>
              <SendOutlined className="text-green-500" />
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: null,
      key: 'operation_delete',
      align: 'center',
      width: 1,
      render: ({ id }) => {
        return (
          <Tooltip title="移除">
            <Popconfirm
              title="確定移除訊息？"
              icon={<CloseCircleTwoTone twoToneColor="#f5222d" />}
              okButtonProps={{ danger: true }}
              okText="確定(不能回復)"
              cancelText="取消"
              onConfirm={() => deleteNotice(id)}
            >
              <Button type="link" size="small">
                <DeleteTwoTone twoToneColor="#f5222d" />
              </Button>
            </Popconfirm>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={notices}
      rowKey={record => record.id}
      bordered
      size="middle"
      pagination={{ position: ['topCenter', 'bottomCenter'] }}
      onRow={(record, _) => {
        return {
          onClick: e => {
            const cellIndex = e.target.cellIndex ?? e.target.offsetParent?.cellIndex;
            if (!!cellIndex && cellIndex < 6) {
              console.log('edit ' + record.id);
              history.push('/message/edit', {
                data: notices.find(value => value.id === record.id),
              });
            }
          },
        };
      }}
    />
  );
}
