import React, { useState } from 'react';
import { Button, Form, Input, Spin, Tooltip, message } from 'antd';
import { LoadingOutlined, CheckOutlined, WarningTwoTone } from '@ant-design/icons';

import { useFirestoreContext } from '../contexts/FirestoreContext';

const InputWithUpdateToDateIcon = ({ name, isUpToDate }) => {
  return (
    <Form.Item name={name} rules={[{ required: true, message: '必需填寫' }]} noStyle>
      <Input
        suffix={
          <Tooltip title={isUpToDate ? '已同步' : '未同步數值'}>
            {isUpToDate ? <CheckOutlined className="text-green-500" /> : <WarningTwoTone twoToneColor="#ffc53d" />}
          </Tooltip>
        }
      />
    </Form.Item>
  );
};

export default function WorshipPage() {
  const { worship, updateWorship } = useFirestoreContext();
  const [isLoading, setIsLoading] = useState(false);

  const onValidSubmit = values => {
    setIsLoading(true);
    console.log('Form validate success');

    updateWorship({
      data: values,
      onSuccess: () => {
        setIsLoading(false);
        message.success('成功更新設定');
      },
      onFail: _ => {
        setIsLoading(false);
        message.error('更改設定失敗。請檢查內容，網路連線或帳號權限。');
      },
    });
  };

  return (
    <Spin spinning={isLoading || !worship} indicator={<LoadingOutlined spin style={{ fontSize: 48 }} />}>
      {worship && (
        <Form
          name="notice"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          size="default"
          onFinish={onValidSubmit}
          initialValues={worship}
        >
          <Form.Item
            label="程序表連結"
            shouldUpdate={(prevValues, curValues) => prevValues.rundownUrl !== curValues.rundownUrl}
          >
            {form => (
              <InputWithUpdateToDateIcon
                name="rundownUrl"
                isUpToDate={worship['rundownUrl'] === form.getFieldValue('rundownUrl')}
              />
            )}
          </Form.Item>
          <Form.Item
            label="直播連結"
            shouldUpdate={(prevValues, curValues) => prevValues.youtubeUrl !== curValues.youtubeUrl}
          >
            {form => (
              <InputWithUpdateToDateIcon
                name="youtubeUrl"
                isUpToDate={worship['youtubeUrl'] === form.getFieldValue('youtubeUrl')}
              />
            )}
          </Form.Item>

          <Form.Item
            label="週三祈禱會"
            shouldUpdate={(prevValues, curValues) => prevValues.wedPrayUrl !== curValues.wedPrayUrl}
          >
            {form => (
              <InputWithUpdateToDateIcon
                name="wedPrayUrl"
                isUpToDate={worship['wedPrayUrl'] === form.getFieldValue('wedPrayUrl')}
              />
            )}
          </Form.Item>
          <Form.Item shouldUpdate>
            {form => {
              const isFormUpToDate = Object.keys(worship).every(value => worship[value] === form.getFieldValue(value));
              if (isFormUpToDate) {
                return null;
              } else {
                return (
                  <>
                    <Button danger className="mx-1" onClick={() => form.resetFields()}>
                      重設
                    </Button>
                    <Button type="primary" className="mx-1" htmlType="submit">
                      更新
                    </Button>
                  </>
                );
              }
            }}
          </Form.Item>
        </Form>
      )}
    </Spin>
  );
}
