import React from 'react';
import { MehOutlined, MessageOutlined } from '@ant-design/icons';

export default function UnderConstructionPage({ children }) {
  return (
    <>
      <div>{children}</div>
      <div className="flex flex-row my-2">
        <MehOutlined style={{ fontSize: '40px' }} className="mt-6" />
        <MessageOutlined style={{ fontSize: '40px' }} className="mx-1" />
      </div>
    </>
  );
}
