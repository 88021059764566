import React, { useContext } from 'react';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

const AuthContext = React.createContext();

export const useAuthContext = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  return <AuthContext.Provider value={useFirebaseAuth()}>{children}</AuthContext.Provider>;
}
