import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import firebase from '../hooks/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Typography, Spin } from 'antd';
import { LockOutlined, LoadingOutlined } from '@ant-design/icons';

import { useAuthContext } from '../contexts/AuthContext';
import PageData from '../pageData';

const { Title } = Typography;

export default function LoginPage({ children }) {
  const history = useHistory();
  const { isSignedIn, role } = useAuthContext();

  const uiConfig = {
    // TODO: redirect for mobile devices
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  if (isSignedIn && role !== undefined) {
    const redirectPath = history.location.state?.loginRedirectFrom ?? '/';
    const data = PageData.find(value => value.path === redirectPath);
    const isPathAllowedForRole = data ? data.roles === '*' || data.roles.some(value => value === role) : false;
    if (isPathAllowedForRole) return <Redirect to={redirectPath} />;
    return <Redirect to="/" />;
  }

  return (
    <Spin spinning={isSignedIn && role === undefined} indicator={<LoadingOutlined spin style={{ fontSize: 48 }} />}>
      <div className="flex flex-col flex-grow h-full justify-start content-center">
        <Title className="flex justify-start">
          <LockOutlined className="mt-1 mr-4" />
          請先登入
        </Title>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    </Spin>
  );
}
