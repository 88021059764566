import { useEffect, useState } from 'react';
import firebase from './firebase';

import { useFirestoreContext } from '../contexts/FirestoreContext';

export default function useFirebaseAuth() {
  const { admins, startAdminsListener } = useFirestoreContext();
  const [currentUser, setCurrentUser] = useState(null);
  const [role, setRole] = useState();
  const signOut = () => firebase.auth().signOut();
  const isSignedIn = !!currentUser;

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log('signed in');
        setRole();
        setCurrentUser(user);
      } else {
        console.log('signed out');
        setCurrentUser();
        setRole();
      }
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      startAdminsListener(() => {
        setRole('');
      });
    }
  }, [currentUser, startAdminsListener]);

  useEffect(() => {
    if (currentUser && admins) {
      const acc = admins?.find(value => value.uid === currentUser.uid);
      setRole(acc ? (acc.isSuperAdmin ? 'SuperAdmin' : 'Admin') : '');
    }
  }, [admins, currentUser]);

  return { isSignedIn, role, currentUser, signOut };
}
