import {
  HomeOutlined,
  SendOutlined,
  BellOutlined,
  LockOutlined,
  YoutubeOutlined,
  SettingOutlined,
  PlusOutlined,
  SmallDashOutlined,
} from '@ant-design/icons';

import LoginPage from './pages/LoginPage';
import MessageListPage from './pages/MessageListPage';
import MessageEditPage from './pages/MessageEditPage';
import WorshipPage from './pages/WorshipPage';
import NotificationPage from './pages/NotificationPage';
import SettingPage from './pages/SettingPage';

const pageData = [
  {
    path: '/',
    title: '主頁',
    icon: <HomeOutlined />,
    roles: '*',
  },
  {
    path: '/worship',
    title: '崇拜',
    icon: <YoutubeOutlined />,
    component: WorshipPage,
    roles: ['Admin', 'SuperAdmin'],
  },
  {
    isGroup: true,
    title: '訊息',
    icon: <BellOutlined />,
    roles: ['Admin', 'SuperAdmin'],
    members: [
      {
        path: '/message/list',
        title: '所有訊息',
        icon: <SmallDashOutlined />,
        component: MessageListPage,
        roles: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/message/add',
        title: '新增訊息',
        icon: <PlusOutlined />,
        component: MessageEditPage,
        roles: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/message/edit',
        title: '編輯訊息',
        icon: <PlusOutlined />,
        isHidden: true,
        component: MessageEditPage,
        roles: ['Admin', 'SuperAdmin'],
      },
    ],
  },
  {
    path: '/notification',
    title: '推送通知',
    icon: <SendOutlined />,
    component: NotificationPage,
    roles: ['Admin', 'SuperAdmin'],
  },
  {
    path: '/setting',
    title: '設定',
    icon: <SettingOutlined />,
    component: SettingPage,
    roles: ['SuperAdmin'],
  },
  {
    path: '/login',
    title: '登入',
    icon: <LockOutlined />,
    component: LoginPage,
    roles: '*',
  },
];

export default pageData;
