import { useEffect, useState, useRef } from 'react';
import firebase from './firebase';

export default function useFirestore() {
  const [notices, setNotices] = useState();
  const [worship, setWorship] = useState();
  const [admins, setAdmins] = useState();
  const noticesRef = useRef(notices);
  const db = firebase.firestore();

  useEffect(() => {
    if (!!db) {
      // notice
      db.collection('notice')
        .orderBy('createTimestamp', 'desc')
        .onSnapshot(querySnapshot => {
          const updatedNotices = noticesRef.current ? [...noticesRef.current] : [];
          querySnapshot.forEach(doc => {
            const docWithId = doc.data();
            docWithId.id = doc.id;
            const index = updatedNotices.findIndex((value, index, array) => value.id === doc.id);
            if (index >= 0) {
              updatedNotices[index] = docWithId;
            } else {
              updatedNotices.push(docWithId);
            }
          });
          setNotices(updatedNotices);
        });

      // worship
      db.collection('worship')
        .doc('links')
        .onSnapshot(doc => {
          setWorship(doc.data());
        });
    }
  }, [db]);

  // notices
  const updateNotice = ({ data, id = undefined, onSuccess = undefined, onFail = undefined }) => {
    // for new notice, we need to set the create time
    if (data.createTimestamp === undefined) {
      data.createTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    }
    const collection = db.collection('notice');
    (!!id ? collection.doc(id).set(data) : collection.add(data)).then(
      docRef => {
        if (!!onSuccess) onSuccess({ id: docRef?.id });
      },
      reason => {
        console.warn(reason);
        if (!!onFail) onFail(reason);
      }
    );
  };

  const deleteNotice = id => {
    if (!id) return;
    console.log('del notice id=' + id);
    db.collection('notice').doc(id).delete();
  };

  // worship
  const updateWorship = ({ data, onSuccess = undefined, onFail = undefined }) => {
    db.collection('worship')
      .doc('links')
      .set(data)
      .then(
        () => {
          if (!!onSuccess) onSuccess();
        },
        reason => {
          console.warn(reason);
          if (!!onFail) onFail(reason);
        }
      );
  };

  // admins
  const startAdminsListener = (onError = null) => {
    console.log('Start listening to admins');
    db.collection('admins').onSnapshot(
      querySnapshot => {
        const list = [];
        querySnapshot.forEach(doc => {
          list.push({ ...doc.data(), uid: doc.id });
        });
        setAdmins(list);
      },
      err => {
        console.log('Stop listening to admins on error.', err);
        setAdmins();
        if (onError) onError(err);
      }
    );
  };

  const addAdmin = async ({ uid, email, displayName, photoURL }) => {
    const collection = db.collection('admins');
    const data = { email: email, displayName: displayName, photoURL: photoURL };
    return collection
      .doc(uid)
      .set(data)
      .then(
        () => {
          console.log('Added ' + email + ' as admin successfully.');
          return true;
        },
        reason => {
          console.warn(reason);
          return false;
        }
      );
  };

  const removeAdmin = async uid => {
    if (!uid) return;
    return db
      .collection('admins')
      .doc(uid)
      .delete()
      .then(
        () => {
          console.log('Remove admin ' + uid + ' successfully.');
          return true;
        },
        rejectReason => {
          console.warn('Failed to remove admin uid=' + uid, rejectReason);
          return false;
        }
      );
  };

  return {
    notices,
    updateNotice,
    deleteNotice,
    worship,
    updateWorship,
    admins,
    startAdminsListener: useRef(startAdminsListener).current,
    addAdmin,
    removeAdmin,
  };
}
