import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from './contexts/AuthContext';

export default function PrivateRoute({ component: Component, roles, ...rest }) {
  const { isSignedIn, role } = useAuthContext();

  return (
    <Route
      {...rest}
      render={props => {
        return isSignedIn && (roles === '*' || roles.some(value => value === role)) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { loginRedirectFrom: rest.path },
            }}
          />
        );
      }}
    ></Route>
  );
}
