import firebase from './firebase';

export const sendNotificationToTopic = ({
  topic,
  title,
  body,
  noticeId = null,
  autoClose = true,
  jumpToNotice = true,
  showDialog = true,
  onSuccess = null,
  onFail = null,
} = {}) => {
  // validate
  if (!topic || !title || !body) {
    console.warn('Invalid params for sending notification, ignored.');
    if (onFail) onFail();
    return;
  }

  console.log('sendNotificationWithTopic, topic=' + topic, {
    title: title,
    body: body,
    noticeId: noticeId,
    autoClose: autoClose,
    jumpToNotice: jumpToNotice,
    showDialog: showDialog,
  });

  // firebase.functions().useEmulator('localhost', 5001);
  var callable = firebase.functions().httpsCallable('sendNotificationToTopic');
  callable({
    topic: topic,
    title: title,
    body: body,
    noticeId: noticeId,
    autoClose: autoClose,
    jumpToNotice: jumpToNotice,
    showDialog: showDialog,
  })
    .then(result => {
      console.log('success', result);
      if (onSuccess) onSuccess();
    })
    .catch(err => {
      console.warn('fail', err.message);
      if (onFail) onFail();
    });
};

export const getAuthenticatedUserByEmail = async email => {
  // firebase.functions().useEmulator('localhost', 5001);
  var callable = firebase.functions().httpsCallable('getAuthenticatedUserByEmail');
  return callable({
    email: email,
  })
    .then(result => {
      console.log('success', result.data);
      return result.data;
    })
    .catch(err => {
      console.warn('fail', err.message);
      return {};
    });
};
