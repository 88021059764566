import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Cascader, Form, Input, Radio, Result, Spin, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useFirestoreContext } from '../contexts/FirestoreContext';

const { TextArea } = Input;

const refInternalPageOption = [
  {
    value: '/',
    label: '主頁',
  },
  {
    value: '/notice',
    label: '通知',
  },
  {
    value: '/worship',
    label: '崇拜直播',
  },
  {
    value: '/rundown',
    label: '程序表',
  },
  {
    value: '/wedpray',
    label: '週三祈禱會',
  },
  {
    value: '/aboutus',
    label: '關於我們',
  },
  {
    value: '/more',
    label: '更多',
  },
];

const isInternalPage = url => {
  if (!url) return false;
  return refInternalPageOption.some(value => value.value === url);
};

export default function MessageEditPage() {
  const history = useHistory();
  const { updateNotice } = useFirestoreContext();
  const [form] = Form.useForm();
  const { data } = history.location.state ?? { data: {} };
  const isInternalRef = isInternalPage(data.refUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [submitResult, setSubmitResult] = useState();
  const [submitSuccessId, setSubmitSuccessId] = useState(data?.id);
  const formFields = [
    { name: 'hasRef', value: !!data.refUrl },
    { name: 'refUrlType', value: isInternalRef ? 'internal' : 'external' },
    { name: 'refPage', value: isInternalRef ? [data.refUrl] : null },
    { name: 'content', value: Array.isArray(data.content) ? data.content.join('\n') : data.content },
  ];

  const onValidSubmit = values => {
    setIsLoading(true);
    if (!!values) {
      // split content into array
      if (!!values.content && !Array.isArray(values.content)) values.content = values.content.split('\n');
      // validate optional values
      if (!values.refTitle) delete values.refTitle;
      if (values.isHidden === undefined) values.isHidden = false;
      if (values.isImportant === undefined) values.isImportant = false;
      // delete control fields
      delete values.refPage;
      delete values.hasRef;
      delete values.refUrlType;
    }

    updateNotice({
      id: data.id,
      data: values,
      onSuccess: ({ id }) => {
        if (!!id) setSubmitSuccessId(id);
        setIsLoading(false);
        setSubmitResult(true);
      },
      onFail: () => {
        setIsLoading(false);
        setSubmitResult(false);
      },
    });
  };

  const onRefUrlTypeChange = e => {
    e.preventDefault();
    if (e.target.value === 'internal') {
      const refUrl = form.getFieldValue('refUrl');
      const isValidPagePath = isInternalPage(refUrl);
      form.setFieldsValue({ refPage: isValidPagePath ? [refUrl] : null });
    }
  };

  if (submitResult) {
    return (
      <Result
        status="success"
        title="成功"
        subTitle={'成功' + (data.id ? '更新' : '新增') + '訊息'}
        extra={[
          <Button type="default" key="message/list" onClick={() => history.push('/message/list')}>
            所有訊息
          </Button>,
          <Button
            type="primary"
            key="notification"
            onClick={() =>
              history.push('/notification', {
                noticeId: submitSuccessId,
              })
            }
          >
            發送推送通知
          </Button>,
        ]}
      />
    );
  } else {
    return (
      <>
        {submitResult === false && (
          <Alert
            message="新增訊息失敗"
            description="請檢查訊息內容，網路連線或帳號權限。"
            type="error"
            showIcon
            closable
            className="mb-4"
            onClose={() => setSubmitResult()}
          />
        )}
        <Spin spinning={isLoading} indicator={<LoadingOutlined spin style={{ fontSize: 48 }} />}>
          <Form
            form={form}
            name="notice"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            size="default"
            onFinish={onValidSubmit}
            fields={formFields}
            initialValues={data}
          >
            <Form.Item label="主題" name="title" rules={[{ required: true, message: '必需填寫' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="內容" name="content" rules={[{ required: true, message: '必需填寫' }]}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              label="參考連結"
              shouldUpdate={(prevValues, curValues) => prevValues.hasRef !== curValues.hasRef}
            >
              {() => {
                return (
                  <Form.Item name="hasRef" valuePropName="checked" noStyle>
                    <Switch />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.hasRef !== curValues.hasRef} noStyle>
              {form => {
                if (!form.getFieldValue('hasRef')) return null;
                else {
                  return (
                    <>
                      <Form.Item label="參考標題" name="refTitle">
                        <Input />
                      </Form.Item>
                      <Form.Item name="refUrlType" label="連結形式">
                        <Radio.Group onChange={onRefUrlTypeChange}>
                          <Radio.Button value="external">外部連結</Radio.Button>
                          <Radio.Button value="internal">轉到頁面</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => prevValues.refUrlType !== curValues.refUrlType}
                        noStyle
                      >
                        {form => {
                          const refUrlType = form.getFieldValue('refUrlType');
                          const isInternal = refUrlType === 'internal';
                          return (
                            <>
                              <Form.Item
                                label={<span className={!isInternal ? null : 'text-transparent'}>參考連結</span>}
                                name="refUrl"
                                rules={[{ required: true, message: '必需填寫' }]}
                                className={!isInternal ? '' : 'h-0 mb-0'}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                label={<span className={isInternal ? null : 'text-transparent'}>參考頁面</span>}
                                name="refPage"
                                hidden={!isInternal}
                              >
                                <Cascader
                                  options={refInternalPageOption}
                                  onChange={value => form.setFieldsValue({ refUrl: value[0] })}
                                />
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.Item>
                    </>
                  );
                }
              }}
            </Form.Item>
            <Form.Item label="隱藏" name="isHidden" valuePropName="checked" tooltip="訊息并不會在通知頁顯示">
              <Switch />
            </Form.Item>
            <Form.Item label="重要" name="isImportant" valuePropName="checked" tooltip="訊息會在通知頁置頂顯示">
              <Switch />
            </Form.Item>
            <Form.Item name="createTimestamp" hidden>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="default" className="mx-1" onClick={() => history.replace('/message/list')}>
                返回
              </Button>
              <Button type="primary" className="mx-1" htmlType="submit">
                {data.id ? '更新' : '新增'}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </>
    );
  }
}
