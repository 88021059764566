import MainLayout from './layouts/MainLayout';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import pageData from './pageData';

import AuthContextProvider from './contexts/AuthContext';
import FirestoreContextProvider from './contexts/FirestoreContext';

import UnderConstructionPage from './pages/UnderConstructionPage';

const PageRoute = member => {
  const { isGroup, path, roles, params = null, title, members, component: PageComponent = null } = member;
  if (isGroup) {
    if (members) {
      return members.map((childMember, _) => PageRoute(childMember));
    } else {
      return null;
    }
  } else {
    if (roles === '*') {
      return (
        <Route
          exact={!params}
          key={path}
          path={!params ? path : path + params}
          component={
            PageComponent
              ? () => <PageComponent>{title}</PageComponent>
              : () => <UnderConstructionPage>{title}</UnderConstructionPage>
          }
        />
      );
    } else {
      return (
        <PrivateRoute
          exact={!params}
          key={path}
          path={!params ? path : path + params}
          roles={roles}
          component={
            PageComponent
              ? () => <PageComponent>{title}</PageComponent>
              : () => <UnderConstructionPage>{title}</UnderConstructionPage>
          }
        />
      );
    }
  }
};

function App() {
  return (
    <FirestoreContextProvider>
      <AuthContextProvider>
        <Router>
          <MainLayout pageData={pageData}>
            <Switch>
              {pageData && pageData.map((data, _) => PageRoute(data))}
              <Route path="/">
                <Redirect to="/" />
              </Route>
            </Switch>
          </MainLayout>
        </Router>
      </AuthContextProvider>
    </FirestoreContextProvider>
  );
}

export default App;
